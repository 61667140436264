.component {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  @media (--viewport-md) { justify-content: flex-end; }

  & > .button {
    width: 60px;
    &:not(:last-child) { @media (--viewport-md) { margin-right: 40px; } }
  }
}

.componentIconWrapper {
  width: 48px !important;
  height: 48px !important;
}
