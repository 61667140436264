.layout {
  padding: 20px 20px 100px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "header"
    "form"
    "visualizer"
    "nav";
  grid-template-rows:
    min-content
    min-content
    minmax(500px, 65vh)
    min-content;
  grid-gap: 20px;

  @media (--viewport-sm) {
    padding: 30px 30px 120px;
    grid-gap: 30px;
  }

  @media (--viewport-md) {
    grid-template-areas:
      "header header"
      "form visualizer"
      "form visualizer"
      "form nav";
    grid-template-rows:
      min-content
      minmax(480px, min-content)
      min-content
      60px;
    grid-template-columns: min-content 1fr;
    padding: 20px 40px 40px;
    grid-row-gap: 20px;
    grid-column-gap: 40px;
  }

  @media (--viewport-lg) {
    padding: 40px 60px 60px;
    grid-row-gap: 40px;
    grid-column-gap: 60px;
  }

  @media screen and (min-height: 930px) {
    padding: 60px;
    grid-row-gap: 60px;
  }

  & > .header { grid-area: header; }
  & > .form { grid-area: form; }
  & > .visualizer { grid-area: visualizer; }
  & > .nav { grid-area: nav; }
}

.visualizer { & > * { height: 100%; } }
.documentation { background-color: var(--color-main); }
.siteFooter { background-color: var(--color-prussian-blue); }

.versionMessage {
  font-size: 12px;
  background-color: var(--color-main);
  text-align: center;
  padding: 10px 30px;

  @media screen and (min-height: 930px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.link {
  color: currentColor;
  text-decoration: currentColor wavy underline;
  text-decoration-skip-ink: none;
  position: relative;
  z-index: 0;
  font-weight: 700;
}
