.component {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap;
}
