.component { text-align: center; }

.heading {
  font-weight: 700;
  color: var(--color-heading);
  @media (--viewport-md) { font-size: 3rem; }
}

.link {
  color: currentColor;
  text-decoration: currentColor wavy underline;
  text-decoration-skip-ink: none;
  position: relative;
  z-index: 0;
  font-weight: 700;
}
