.component {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  & > *:not(:last-child) { margin-bottom: 14px; }

  & > .numberLabel { width: 60%; }

  & > .numberInput {
    width: 40%;
    height: var(--input-height);
  }

  & > .range { width: 100%; }
}

.numberInput {
  border: 3px solid var(--color-input-border);
  border-radius: 4px;
  background-color: var(--color-background);
  padding: 6px;
  font-size: 1rem;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.numberLabel {
  display: block;
  user-select: none;
}

/* from http://danielstern.ca/range.css/ */
.range {
  -webkit-appearance: none;
  cursor: pointer;
  background-color: transparent;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    background-color: var(--color-stroke);
    border-radius: 5px;
  }

  &::-moz-range-track {
    width: 100%;
    height: 10px;
    background-color: var(--color-stroke);
    border-radius: 5px;
  }

  &::-webkit-slider-thumb {
    border: 3px solid var(--color-stroke);
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--color-main);
    -webkit-appearance: none;
    margin-top: -5px;
  }

  &::-moz-range-thumb {
    border: 3px solid var(--color-stroke);
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--color-main);
  }

  &::-ms-thumb {
    border: 3px solid var(--color-stroke);
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: var(--color-main);
  }

  &::-ms-track {
    width: 100%;
    height: 10px;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  &::-ms-fill-upper {
    background: var(--color-stroke);
    border-radius: 0;
  }

  &::-ms-fill-lower {
    background: var(--color-stroke);
    border-radius: 0;
  }
}
