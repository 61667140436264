.component,
.componentLink {
  cursor: pointer;
  position: relative;
  height: 60px !important;
  z-index: 0;
  font-weight: 700;

  & > .innerButton,
  & > .innerLink {
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 3px solid var(--color-stroke);
    border-radius: 4px;
    transform-origin: top right;
    transition-timing-function: cubic-bezier(0.165, -0.235, 0.58, 1.18);
    transition-property: transform;
    transition-duration: 200ms;
    will-change: transform;
  }

  &:hover,
  &:focus {
    &::after {
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transform: translate(-6px, 6px);
      transition-duration: 275ms;
    }
  }
}

.innerLink,
.innerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-button-text);
  border: 3px solid var(--color-stroke);
  border-radius: 4px;
}

.innerButton { background-color: var(--color-button); }

.innerLink {
  transition: color 200ms;
  background-color: var(--color-button-link);
  &.isActive { background-color: var(--color-button-link-active); }
}
