.component {
  display: flex;
  align-items: center;
  & > .checkboxLabel { width: 100%; }
}

.checkboxInput {
  display: none;
  &:checked + .checkboxLabel::after {
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: scale(1);
    transition-duration: 275ms;
  }
}

.checkboxLabel {
  line-height: var(--input-height);
  position: relative;
  display: block;

  &::before,
  &::after {
    display: block;
    position: absolute;
    width: var(--input-height);
    height: var(--input-height);
    right: 0;
    bottom: 0;
  }

  &::after {
    font-size: 1.6em;
    content: '✓';
    line-height: var(--input-height);
    text-align: center;
    transform: scale(0);
    transition-duration: 225ms;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }

  &::before {
    content: '';
    border: 3px solid var(--color-stroke);
    border-radius: 4px;
    background-color: var(--color-background);
  }
}
