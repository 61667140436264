.component {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 0;

  & > .opacityLayout,
  & > .rotateLayout,
  & > .scaleLayout {
    width: 66.67%;
    height: 66.67%;
  }

  & > .translateLayout {
    width: 50%;
    height: 50%;
  }
}

.progress {
  text-align: right;
  font-size: 24px;
  line-height: 1;
  display: block;
}

.componentScale,
.componentOpacity,
.componentRotate,
.componentTranslate {
  position: relative;
  z-index: 0;

  & > .progress {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 1;
  }
}

.componentRotate {
  & > .rotateContainer {
    width: 100%;
    height: 100%;
  }
}

.rotateContainer {
  background-color: var(--color-secondary);
  border: 3px solid var(--color-stroke);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  & > .rotate {
    width: 90%;
    height: 50%;
  }
}

.componentScale {
  position: relative;
  background-color: var(--color-secondary);

  & > .scale {
    width: 100%;
    height: 100%;
  }

  & > .scaleMin,
  & > .scaleMax { position: absolute; }

  & > .scaleMin {
    top: 25%;
    left: 25%;
    width: 50%;
    height: 50%;
  }

  & > .scaleMax {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.scaleMax,
.scaleMin {
  border: 3px solid var(--color-stroke);
  border-radius: 4px;
}

.rotate,
.scale {
  background-color: var(--color-highlight);
  border: 5px solid var(--color-stroke);
  border-radius: 4px;
  will-change: transform;
}

.componentOpacity {
  display: flex;
  align-items: center;

  & > :not(:last-child) { margin-right: 30px; }

  & > .opacityContainer {
    flex-grow: 1;
    align-self: stretch;
  }

  & > .legend {
    flex: 0 0 40px;
    height: 100%;
  }

  & > .progress {
    right: 60px;
  }
}

.legend {
  position: relative;

  & > .bar {
    width: 100%;
    height: 100%;
  }

  & > .current {
    position: absolute;
    width: 80px;
    height: 20px;
    margin-left: -20px;
    margin-top: -10px;
  }
}

.bar {
  border-radius: 4px;
  border: 3px solid var(--color-stroke);
  background-color: var(--color-secondary);
  background-image: linear-gradient(
    to top,
    rgba(254, 246, 228, 0),
    rgba(254, 246, 228, 1)
  );
}

.current {
  transform-origin: bottom;
  will-change: transform;

  & > .currentMarker {
    width: 100%;
    height: 100%;
  }
}

.opacityContainer {
  border: 3px solid var(--color-stroke);
  background-color: var(--color-secondary);
  border-radius: 4px;

  & > .opacity {
    width: 100%;
    height: 100%;
  }
}

.opacity {
  background-color: var(--color-highlight);
  will-change: opacity;
}

.componentTranslate {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  & > .translate {
    width: 35%;
    height: 60%;
    top: 30%;
    position: relative;
  }

  & > .translateBoundariesBorder,
  & > .translateBoundariesBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.translateBoundariesBorder {
  border: 3px solid var(--color-stroke);
  border-radius: 4px;
}

.translateBoundariesBackground { background-color: var(--color-secondary); }

.translate {
  background-color: var(--color-highlight);
  border: 5px solid var(--color-stroke);
  border-radius: 20px;
  will-change: transform;
}
