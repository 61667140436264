.component {
  & > .select { width: 100%; }
}

.select {
  display: block;
  color: var(--color-body);
  line-height: 1.3;
  padding: 0 6px;
  border: 3px solid var(--color-stroke);
  height: var(--input-height) !important;
  border-radius: 4px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: var(--color-background);
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsPSIjMDA5Ij4KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMy45IDIwTDkgMTUuMWwyLjEtMi4xIDQuOSA0LjkgNC45LTQuOSAyLjEgMi4xLTcuMSA3LjEtMi0yLjJ6Ii8+Cjwvc3ZnPg==');
  background-repeat: no-repeat, repeat;
  background-position: right 6px top 50%, 0 0;
  background-size: 1.6em auto, 100%;

  & > option { color: var(--color-body); }

  &::-ms-expand { display: none; }

  &:hover,
  &:focus { border-color: var(--color-stroke); }

  &:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(51, 104, 191, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: var(--color-body);
    outline: none;
  }
}
